@import '../globals/mixins';

.page_home {
  // h1 {
  // 	text-align: left;
  // }
  .socialMediaButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: $size-s;
    button {
      height: $size-l;
      width: $size-l;
    }
  }
  .partners {
    & > div {
      & > h3 {
        margin: auto;
        text-align: center;
      }
      & > ul {
        text-align: center;

        & > li {
          margin: $size-sm $size-ss;
          width: auto;
          display: inline-block;
          vertical-align: top;

          @include screen-m {
            margin: $size-sm;
          }

          & > div {
            @include rounded;
            border: 1px solid $color-subtl1;
            height: 136px;
            margin: auto;
            padding: $size-xs;
            width: 136px;
          }
        }
      }
    }
  }
}
